import get from 'lodash/get'
import { connect } from 'react-redux'

import { REVIEW_AND_EXPORT_KEY } from 'shared/constants/report/keysAndDataPaths'

import { ReportStates } from '../../constants'
import { updateReportState } from '../../redux/actions/report'

import { hasPermission } from '../../../../../shared/helpers/permissions'

import ReportStatus from './ReportStatus'

const FORM_KEY = `report.reportData.${REVIEW_AND_EXPORT_KEY}`

function canSetApprove(user, reportState) {
  return (
    hasPermission('report.status.approve', user?.permissions) &&
    (reportState === ReportStates.REVIEW || reportState === ReportStates.SUBMITTED)
  )
}

function canSetReview(user, reportState) {
  return (
    hasPermission('report.status.review', user?.permissions) &&
    reportState !== ReportStates.REVIEW &&
    reportState !== ReportStates.SUBMITTED
  )
}

function canSetDraft(user, reportState) {
  return hasPermission('report.status.draft', user?.permissions) && reportState !== ReportStates.DRAFT
}

function canSetSubmitted(user, reportState) {
  return hasPermission('report.status.submit', user?.permissions) && reportState === ReportStates.APPROVED
}

export default connect(
  (state, ownProps) => {
    const authenticatedUser = get(state, 'authentication.user', {})
    const reportState = get(state, `${FORM_KEY}.state`)
    return {
      approvedAt: get(state, `${FORM_KEY}.approvedAt`),
      approvedBy: get(state, `${FORM_KEY}.approvedBy.username`),
      canSetApprove: canSetApprove(authenticatedUser, reportState),
      canSetDraft: canSetDraft(authenticatedUser, reportState),
      canSetReview: canSetReview(authenticatedUser, reportState),
      canSetSubmitted: canSetSubmitted(authenticatedUser, reportState),
      nextFormPath: ownProps.nextFormPath,
      reportState,
      showApprovedBy: reportState === ReportStates.APPROVED || reportState === ReportStates.SUBMITTED,
      showSubmittedBy: reportState !== ReportStates.DRAFT,
      submittedAt: get(state, `${FORM_KEY}.submittedAt`),
      submittedBy: get(state, `${FORM_KEY}.submittedBy.username`),
      salesforceLink: get(state, 'report.reportData.salesforceLink'),
      reportId: get(state, 'report.reportData._id'),
    }
  },
  { updateReportState }
)(ReportStatus)
