export const ENABLE_AUTOMATED_INSPECTION_PREFILL = 'automated-inspection-prefill'
export const ENABLE_AUTO_SALES_VALUE_CALCULATION = 'automated-sales-value-conclusion-calculation'
export const FEATURE_FLAG_AI_APPRAISAL_ASSISTANT = 'ai-appraisal-assistant'
export const FEATURE_FLAG_AUTO_SALES_COMPS_RULE_EDIT = 'automated-sales-comps-rule-edit'
export const FEATURE_FLAG_AUTOMATED_EXPENSE_HISTORY = 'automated-expense-history'
export const FEATURE_FLAG_AUTOMATED_EXPENSE_FORECAST = 'automated-expense-forecast'
export const ENABLE_AUTO_MARKET_VACANCY_RATES = 'automate-market-vacancy-rates'
export const ENABLE_AUTO_RESI_RENT_COMP_PICKER = 'automated-resi-rent-comps'
export const ENABLE_RES_RENT_RECONCILIATION_AUTOMATION = 'res-rent-reconciliation-automation'
export const ENABLE_PSF_TABLE_DEFAULTS_CHECKED = 'psf-table-defaults-checked'
export const ENABLE_AUTO_RESIDENTIAL_INCOME_PREFILL = 'auto-complete-rent-comps-and-market-conclusion'
export const FEATURE_FLAG_SIMPLIFIED_EXPENSES_STRUCTURE = 'simplified-expenses-structure'
export const DELEGATION_GROUP = 'delegation-group'
export const FEATURE_FLAG_AUTO_EXPENSE_COMPS = 'automated-expense-comps'
export const FEATURE_FLAG_SIMPLIFIED_EXPENSE_COMPS = 'simplified-expense-comps'
export const ENABLE_SALES_ADJUSTMENT_GRID_V2 = 'enable-sales-adjustment-grid-v2'
export const CP_PROPERTY_SEARCH = 'cp-property-search'
export const USE_COMMERCIAL_SUBTYPE = 'use-commercial-subtype'
export const ENABLE_COMMERCIAL_ADJUSTMENT_GRID_V2 = 'enable-commercial-adjustment-grid-v2'
export const NO_INCOME_APPROACH = 'no-income-approach'
export const COMMERCIAL_COMP_DETAILS = 'commercial-comp-details'
export const ENABLE_GLEAN = 'enable-glean'
export const ENABLE_JOB_CLOSE_OUT = 'enable-job-close-out'
